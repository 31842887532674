import React from 'react'
import styled from 'styled-components'

export default function Box() {
    return (
        <Boxe className='boxe'>
            
        </Boxe>
    )
}


const Boxe =  styled.div`

    height: 8rem;
    background: radial-gradient(circle farthest-side,#2A355A,#090C17);
`
