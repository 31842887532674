import React from 'react';
import styled from 'styled-components'
import background from '../../images/archivos-17.jpg';

const Header = () => {
    return (
        <Container>
            <div className="title">
                Nuestra Historia, tu historia
            </div>
            <div className="body">
                <strong>Vinimay App</strong> nació de la pasión de Gabriel por las camisetas de fútbol. 
                Quería crear un espacio donde los coleccionistas pudieran exhibir sus tesoros al mundo. 
                Así surgió Vinimay, una plataforma global diseñada para que <strong>los fanáticos compartan sus 
                colecciones e interactúen con otros fanáticos</strong>. Hoy, Vinimay conecta a miles de aficionados 
                que celebran su amor por el fútbol a través de sus camisetas. Actualmente, la plataforma 
                protege más de 30,000 camisetas con historia, cada una con un relato único que contar.
            </div>
            <div className="bottom">
                ¿Ya sos parte de vinimay?
            </div>
        </Container>
    );


}


const Container = styled.div`
        height:70vh;
        display:flex;
        align-items:center;
        justify-content:center;
        text-align:center;
        flex-direction:column;
        background-image: url(${background});
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;

        .title{
            font-size: 3em;
            text-transform:uppercase;
            letter-spacing:2px;
            color:#A8C813;
            font-weight:600;
            text-shadow: #000 1px 0 10px;
            padding:2rem;
        }
        .body{
            color: white;
            font-size: 2em;
            font-family: "FSJoey";
            line-height:1.125em;
            padding:2rem;
            text-align:center;
            letter-spacing: 1px;
            /* Arriba | Derecha | Abajo | Izquierda */
            margin: 0 4em 0 4em;
        }

        .bottom{
            font-size: 2em;
            text-transform:uppercase;
            letter-spacing:2px;
            color:#A8C813;
            font-weight:600;
            text-shadow: #000 1px 0 10px;
            padding:2rem;
        }


        @media screen and (max-width: 769px){
            height:auto;
            .body{
                margin: 0;
            }
        }

    `

export default Header;